import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

// material-ui
import { useMediaQuery, Box, Container, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import
import Drawer from './Drawer';
import Header from './Header';
import Footer from './Footer';
// import HorizontalBar from './Drawer/HorizontalBar';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

import useConfig from 'hooks/useConfig';
import { useMenuConfig } from 'hooks/useMenuConfig';
import { openDrawer } from 'store/reducers/menu';
import { LAYOUT_CONST } from 'config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { verticalNavigation } = useMenuConfig();
  const { container, miniDrawer, menuOrientation } = useConfig();
  const { drawerOpen, userRole } = useSelector((state) => state.menu);

  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downMD;

  // drawer toggler
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // See if welcome cookie is set
  useEffect(() => {
    const cookies = new Cookies();
    if (!cookies.get('welcome')) navigate('/welcome');
  }, [navigate]);

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!downMD);
      dispatch(openDrawer({ drawerOpen: !downMD }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downMD]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {!isHorizontal && <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />}
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1, p: 0, backgroundColor: 'white' }}>
        <Toolbar
          sx={{
            height: '80px'
          }}
        />
        <Breadcrumbs
          navigation={verticalNavigation[userRole]}
          title
          titleBottom
          card={false}
          divider={false}
          separator={<FontAwesomeIcon icon={faGreaterThan} color="white" fontSize={'0.8rem'} alignSelf="center" />}
          sx={{ pt: 4, mb: 5, backgroundColor: theme.palette.primary.main, borderRadius: '0 0 100px 100px' }}
        />
        <Container
          maxWidth={container ? 'xl' : false}
          sx={{
            position: 'relative',
            minHeight: 'calc(100vh - 110px)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
};

export default MainLayout;
