import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';

// pages routing
const Home = Loadable(lazy(() => import('pages/client/home')));
const SearchVUs = Loadable(lazy(() => import('pages/client/searchVUs')));
const ListVUs = Loadable(lazy(() => import('pages/client/listVUs')));
const DetailsVU = Loadable(lazy(() => import('pages/client/detailsVU')));
const Services = Loadable(lazy(() => import('pages/client/services')));
const About = Loadable(lazy(() => import('pages/client/about')));

const ClientRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '',
      element: <Home />
    },
    {
      path: 'home',
      element: <Home />
    },
    {
      path: 'search-vu',
      element: <SearchVUs />
    },
    {
      path: 'list',
      element: <ListVUs />
    },
    {
      path: 'details/:id',
      element: <DetailsVU />
    },
    {
      path: 'services',
      element: <Services />
    },
    {
      path: 'about',
      element: <About />
    }
  ]
};

export default ClientRoutes;
