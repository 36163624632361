import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { createContext, useCallback, useEffect } from 'react';

// project import
import axios from 'axios';
import useConfig from 'hooks/useConfig';
import { openSnackbar } from 'store/reducers/snackbar';

// const
const BASE_URL = process.env.REACT_APP_API_HOST;
const AxiosInstance = axios.create({ baseURL: BASE_URL });

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const UtilsContext = createContext(null);

export const UtilsProvider = ({ children }) => {
  const intl = useIntl();
  const { i18n } = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    if (document.getElementsByName('description'))
      document.getElementsByName('description').content = intl.formatMessage({ id: 'meta-wdescription' });
  }, [i18n, intl]);

  const displaySnackbar = useCallback(
    (message, color = 'success', variant = 'filled', actionButton = false) => {
      dispatch(
        openSnackbar({
          open: true,
          autoHideDuration: 6000,
          message,
          variant: 'alert',
          alert: { color, variant },
          close: true,
          actionButton
        })
      );
    },
    [dispatch]
  );

  return <UtilsContext.Provider value={{ displaySnackbar }}>{children}</UtilsContext.Provider>;
};

UtilsProvider.propTypes = {
  children: PropTypes.node
};

export default UtilsContext;
export { AxiosInstance };
