import { lazy } from 'react';

// project import
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// pages routing

const Info = Loadable(lazy(() => import('pages/info')));
const Welcome = Loadable(lazy(() => import('pages/auth/welcome')));
const Login = Loadable(lazy(() => import('pages/auth/login')));

// ==============================|| PROVIDER ROUTING ||============================== //

const InfoRoutes = {
  path: '/',
  element: <CommonLayout />,
  children: [
    {
      path: 'welcome',
      element: <Welcome />
    },
    {
      path: 'welcome2',
      element: <Login />
    },
    {
      path: 'contact-us',
      element: <Info />
    }
  ]
};

export default InfoRoutes;
