import { apiGet, apiPost, apiPut } from '.';

export const getUser = async () => {
  const url = '/api/v1/users';

  const res = await apiGet(url);
  return res;
};

export const putUser = async (id, body) => {
  const url = `/api/v1/users/${id}`;
  return apiPut(url, body);
};

export const putCompany = async (id, body) => {
  const url = `/api/v1/users/${id}`;
  return apiPut(url, body);
};

export const logout = async () => {
  const url = '/api/v1/users/logout';
  apiPost(url);
};

export const login = async (auth) => {
  const url = '/api/v1/users/public-login';
  const body = {
    auth: {
      username: auth.username,
      password: auth.password
    }
  };

  return apiPost(url, body);
};

export const checkUsername = async (username) => {
  const url = `/api/v1/public-users/resetPassword?username=${username}`;
  const res = await apiGet(url, {}, false);
  return res;
};

export const validateAccount = async (queryString) => {
  const url = `/api/v1/public-user/validateMail?${queryString}`;
  const res = await apiGet(url);
  return res;
};

export const resetPassword = async (id, data) => {
  const url = `/api/v1/users/${id}/reset-password`;
  const res = await apiPut(url, data, {}, false);
  return res;
};

export const setPassword = async (id, data) => {
  const url = `/api/v1/users/${id}/set-password`;
  const res = await apiPut(url, data, {}, false);
  return res;
};

export const getUserLanguage = async () => {
  // const url = '/api/v1/users/lang';

  // const res = await apiGet(url);
  return 'fr';
};

export const putUserLanguage = async () => {
  // const url = '/api/v1/users/lang';

  // const res = await apiPut(url, data);
  return 'fr';
};

export const getAddresses = async () => {
  const url = `/api/v1/users/addresses`;

  const res = await apiGet(url);
  return res;
};

export const createAddress = async (data) => {
  const url = '/api/v1/users/addresses';
  const res = await apiPost(url, data);
  return res;
};

export const putAddress = async (id, data) => {
  const url = `/api/v1/users/addresses/${id}`;
  const res = await apiPut(url, data);
  return res;
};

export const loginAsUser = async (partner_id, data) => {
  const url = `/api/v1/users/login-as-user?partner_id=${partner_id}`;
  return apiGet(url, data);
};

//Public API
export const register = async (values) => {
  const url = '/api/v1/public-users';
  const body = {
    values: {
      name: values.name,
      surname: values.surname,
      displayName: `${values.name} ${values.surname}`,
      email: values.email,
      phone: values.phone,
      password: values.password,
      role: values.email,
      address: values.address,
      address_place_id: values.address_place_id,
      company: values.company,
      newsletter_notification: values.newsletter_notification,
      system_notification: values.system_notification,
      vat: values.vat
    }
  };

  return apiPost(url, body);
};

export const getPublicUserCompanyByFirebaseId = async (uid, data) => {
  const url = `/api/v1/public-user/company?uid=${uid}`;
  return apiGet(url, data);
};

export const getPublicUserContactByFirebaseId = async (uid, data) => {
  const url = `/api/v1/public-users?uid=${uid}`;
  return apiGet(url, data);
};

export const putPublicUserContact = async (uid, data) => {
  const url = `/api/v1/public-users?uid=${uid}`;
  return apiPut(url, data);
};

export const putPublicUserCompany = async (id, body) => {
  const url = `/api/v1/public-user/company/${id}`;
  return apiPut(url, body);
};

export const postPublicUserCompanyFile = async (id, fileName, file) => {
  const url = `/api/v1/public-user/company/${id}/send-file`;
  const metadata = JSON.stringify({ fileName });
  const blobMetadata = new Blob([metadata], {
    type: 'application/json'
  });

  const formData = new FormData();

  formData.append('metadata', blobMetadata, 'metadata');
  formData.append('file', file);

  return apiPost(url, formData);
};
