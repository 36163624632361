import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Card, CardActionArea, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

// header style
const defaultHeaderSX = {
  p: 2.5,
  '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

// ==============================|| CUSTOM - MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      focusHighlight = true,
      children,
      subheader,
      content = true,
      contentSX = {},
      headerSX = defaultHeaderSX,
      darkTitle,
      divider = true,
      elevation,
      secondary,
      shadow,
      sx = {},
      title,
      titleVariant,
      modal = false,
      onclick = undefined,
      navigateRoute,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();
    const navigate = useNavigate();
    boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

    const StyledCardActionArea = styled(CardActionArea)(
      () => `
        .MuiCardActionArea-focusHighlight {
            background: transparent;
        }`
    );
    const cardContent = () => {
      return (
        <>
          {/* card header and action */}
          {!darkTitle && title && (
            <CardHeader
              sx={headerSX}
              titleTypographyProps={{ variant: titleVariant || 'subtitle1' }}
              title={title}
              action={secondary}
              subheader={subheader}
            />
          )}
          {darkTitle && title && (
            <CardHeader
              sx={headerSX}
              title={
                <Typography variant="h4" sx={headerSX}>
                  {title}
                </Typography>
              }
              action={secondary}
            />
          )}

          {/* content & header divider */}
          {title && divider && <Divider />}

          {/* card content */}
          {content && <CardContent sx={contentSX}>{children}</CardContent>}
          {!content && children}
        </>
      );
    };

    return (
      <Card
        elevation={elevation || 0}
        ref={ref}
        {...others}
        sx={{
          position: 'relative',
          border: border ? '1px solid' : 'none',
          borderRadius: 1,
          borderColor: theme.palette.mode === 'dark' ? theme.palette.grey.A800 : theme.palette.grey.A800,
          boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
          ':hover': {
            boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
          },
          ...(modal && {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: `calc( 100% - 50px)`, sm: 'auto' },
            '& .MuiCardContent-root': {
              overflowY: 'auto',
              minHeight: 'auto',
              maxHeight: `calc(100vh - 200px)`
            }
          }),
          ...sx
        }}
      >
        {navigateRoute ? (
          focusHighlight ? (
            <CardActionArea
              sx={{ height: '100%' }}
              onClick={() => {
                navigate(navigateRoute);
              }}
            >
              {cardContent()}
            </CardActionArea>
          ) : (
            <StyledCardActionArea
              onClick={() => {
                navigate(navigateRoute);
              }}
            >
              {cardContent()}
            </StyledCardActionArea>
          )
        ) : onclick ? (
          focusHighlight ? (
            <CardActionArea
              sx={{ height: '100%' }}
              onClick={() => {
                onclick();
              }}
            >
              {cardContent()}
            </CardActionArea>
          ) : (
            <StyledCardActionArea
              onClick={() => {
                onclick();
              }}
            >
              {cardContent()}
            </StyledCardActionArea>
          )
        ) : (
          cardContent()
        )}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  focusHighlight: PropTypes.bool,
  children: PropTypes.node,
  subheader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  headerSX: PropTypes.object,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  divider: PropTypes.bool,
  elevation: PropTypes.number,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  titleVariant: PropTypes.string,
  modal: PropTypes.bool,
  navigateRoute: PropTypes.string,
  onclick: PropTypes.func
};

export default MainCard;
