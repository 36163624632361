// material-ui
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import ContactDialog from 'components/dialogs/transporter/ContactDialog';

// ==============================|| HEADER CONTENT - LOCALIZATION ||============================== //

const ContactButton = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    console.log('🚀 ~ handleClickOpen ~ handleClickOpen:', handleClickOpen);
    setOpen(true);
  };
  const handleMenuClose = () => {
    handleClose(false);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        variant="contained"
        sx={{
          borderRadius: '7px',
          border: `1px solid white`,
          backgroundColor: theme.palette.primary.main,
          textTransform: 'none'
        }}
        onClick={() => handleClickOpen()}
      >
        <Typography sx={{ fontFamily: 'Promoove', fontWeight: 'bold', fontSize: '1.2vw' }} color={'white'}>
          Contact-Nous
        </Typography>
      </Button>

      {open && <ContactDialog open={open} onClose={handleMenuClose} />}
    </Box>
  );
};

export default ContactButton;
