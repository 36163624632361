import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';
import PromooveLogoO from 'assets/images/logos/PromooveLogoO';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState();
  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { drawerOpen, openItems, userRole } = useSelector((state) => state.menu);

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => <Link {...props} to={item.url} target={itemTarget} ref={ref} />)
  };
  if (item?.external) {
    listItemProps = {
      component: 'a',
      href: item.url,
      target: itemTarget
    };
  }

  const { pathname } = useLocation();

  useEffect(() => {
    setIsSelected(openItems[userRole].findIndex((id) => id === item.id) > -1);
  }, [openItems, item, userRole]);

  // active menu item on page load
  useEffect(() => {
    if (pathname && pathname.includes('product-details')) {
      if (item.url && item.url.includes('product-details')) {
        dispatch(activeItem({ userRole, openItem: [item.id] }));
      }
    }

    if (pathname.includes(item.url)) {
      dispatch(activeItem({ userRole, openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname, userRole]);

  // const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? 'text.primary' : theme.palette.primary.main;

  return (
    <>
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          height: '100%',
          zIndex: 1201,
          ...(drawerOpen && {
            '&:hover': {
              color: theme.palette.primary[200],
              bgcolor: `unset`
            },
            '&.Mui-selected': {
              bgcolor: `unset`,
              color: iconSelectedColor,
              '&:hover': {
                color: iconSelectedColor,
                bgcolor: `unset`
              }
            },
            color: theme.palette.blue.main
          }),
          ...(!drawerOpen && {
            '&:hover': {
              color: theme.palette.blue[100]
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: `unset`
              },
              color: `${theme.palette.blue.main}`,
              bgcolor: `unset`
            }
          })
        }}
      >
        <ListItemText
          primary={
            <Typography
              variant="h6"
              color="inherit"
              textAlign={'center'}
              display={'flex'}
              justifyContent={'center'}
              style={{ fontFamily: 'Promoove', fontSize: '1.2vw', fontWeight: 'bold', width: downLG ? 'min-content' : 'max-content' }}
            >
              {isSelected && (
                <ListItemIcon style={{ width: '2rem', marginRight: '1rem' }}>
                  <PromooveLogoO fill={iconSelectedColor} />
                </ListItemIcon>
              )}
              {item.title}
            </Typography>
          }
          sx={{ display: 'flex', justifyContent: 'center' }}
        />
        {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
