import { useRoutes } from 'react-router-dom';

// project import
import InfoRoutes from './InfoRoutes';
import ClientRoutes from './ClientRoutes';
// import TermsRoutes from './TermsRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const serviceRoutes = [ClientRoutes, InfoRoutes];

  const routes = useRoutes(serviceRoutes);
  return routes;
}
