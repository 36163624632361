// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { WalletOutlined, UnorderedListOutlined, HomeOutlined } from '@ant-design/icons';

// icons
const icons = {
  WalletOutlined,
  HomeOutlined,
  UnorderedListOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

export const salesVertical = {
  id: 'group-sales',
  tags: ['development', 'Provider', 'Fournisseur'],
  title: <FormattedMessage id="sales" />,
  type: 'group',
  children: [
    {
      id: 'home-sales',
      icon: icons.HomeOutlined,
      title: <FormattedMessage id="home" />,
      url: '/',
      type: 'item'
    },
    {
      id: 'my-sales',
      title: <FormattedMessage id="vehicules neuf" />,
      url: '/sales/my-sales',
      icon: icons.WalletOutlined,
      type: 'item'
    },
    {
      id: 'my-lists-vo',
      title: <FormattedMessage id="vehicules ocassion" />,
      type: 'item',
      url: '/sales/my-lists-vo',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'my-lists-vo2',
      title: <FormattedMessage id="Nous Services" />,
      type: 'item',
      url: '/sales/my-lists-vo',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'my-lists-vo3',
      title: <FormattedMessage id="Nous" />,
      type: 'item',
      url: '/sales/my-lists-vo',
      icon: icons.UnorderedListOutlined
    }
  ]
};
export const salesHorizontal = {
  items: [
    {
      id: 'home-sales',
      icon: icons.HomeOutlined,
      title: <FormattedMessage id="home" />,
      url: '/',
      type: 'item'
    },
    {
      id: 'my-sales',
      title: <FormattedMessage id="vehicules neuf" />,
      url: '/sales/my-sales',
      icon: icons.WalletOutlined,
      type: 'item'
    },
    {
      id: 'my-lists-vo',
      title: <FormattedMessage id="vehicules ocassion" />,
      type: 'item',
      url: '/sales/my-lists-vo',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'my-lists-vo',
      title: <FormattedMessage id="Nous Services" />,
      type: 'item',
      url: '/sales/my-lists-vo',
      icon: icons.UnorderedListOutlined
    },
    {
      id: 'my-lists-vo',
      title: <FormattedMessage id="Nous" />,
      type: 'item',
      url: '/sales/my-lists-vo',
      icon: icons.UnorderedListOutlined
    }
  ]
};
