// third-party
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  needRefresh: false
};

// ==============================|| SLICE - REFRESH ||============================== //

const refresh = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    executeRefresh(state, action) {
      state.needRefresh = action.payload.needRefresh;
    }
  }
});

export default refresh.reducer;

export const { executeRefresh } = refresh.actions;

export { initialState };
