// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

export const infoVertical = {
  id: 'group-info',
  type: 'group',
  children: [{ id: 'contact-us', title: <FormattedMessage id="contact-us" />, url: '/contact-us', type: 'item' }]
};
export const infoHorizontal = {
  items: [{ id: 'contact-us', title: <FormattedMessage id="contact-us" />, url: '/contact-us', type: 'item' }]
};
