import { useMemo } from 'react';

// material-ui
import { Box, Grid, useMediaQuery } from '@mui/material';

// project import
import Application from './Application';
import Localization from './Localization';
import Customization from './Customization';

import useAuth from 'hooks/useAuth';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

import { LAYOUT_CONST } from 'config';
import HorizontalNavigation from 'layout/MainLayout/Drawer/DrawerContent/HorizontalNavigation';
import ContactButton from '../../../../components/navbar/contactButton';
import PromooveLogo from 'assets/images/logos/PromooveLogo';
import { useSelector } from 'react-redux';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n, menuOrientation } = useConfig();
  const { user } = useAuth();
  const { drawerOpen } = useSelector((state) => state.menu);
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const isHorizontal = menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const localization = useMemo(() => <Localization />, [i18n]);

  return (
    <>
      {isHorizontal && <DrawerHeader open={true} />}
      {isHorizontal && (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
          <HorizontalNavigation />
        </Box>
      )}

      {isHorizontal && <ContactButton />}
      {isHorizontal && localization}

      {!isHorizontal && !drawerOpen && (
        <Grid container>
          <Grid item xs={10} display={'flex'} justifyContent={'center'}>
            <Box sx={{ width: '60%', justifySelf: 'center' }}>
              <PromooveLogo />
            </Box>
          </Grid>
          <Grid item xs={2} alignContent={'center'}>
            <Box sx={{ width: '100%', textAlign: 'right', ml: { xs: 0, md: 1 } }}>{localization}</Box>
          </Grid>
        </Grid>
      )}
      {user?.tags?.length > 1 && <Application />}
      {user?.tags?.includes('development') && <Customization />}
    </>
  );
};

export default HeaderContent;
