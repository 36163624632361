import { AxiosInstance } from 'contexts/FirebaseContext';

export const apiGet = (url, config = {}, credentials = true) => {
  if (credentials) {
    config.withCredentials = credentials;
  }
  return AxiosInstance.get(url, config);
};

export const apiPost = (url, data = {}, config = {}) => {
  config.withCredentials = true;
  return AxiosInstance.post(url, data, config);
};

export const apiPut = (url, data = {}, config = {}, credentials = true) => {
  if (credentials) {
    config.withCredentials = credentials;
  }
  return AxiosInstance.put(url, data, config);
};

export const isSessionIdCorrect = () => {};
