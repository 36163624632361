// third-party
import { FormattedMessage } from 'react-intl';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

export const purchasesVertical = {
  id: 'group-purchases',
  tags: ['development', 'Public Client'],
  type: 'group',
  children: [
    {
      id: 'home',
      title: <FormattedMessage id="home" />,
      url: '/',
      type: 'item'
    },
    {
      id: 'list',
      title: <FormattedMessage id="auth-footer.menu1" />,
      url: '/list',
      type: 'item'
    },
    {
      id: 'services',
      title: <FormattedMessage id="auth-footer.menu2" />,
      type: 'item',
      url: '/services'
    },
    {
      id: 'about',
      title: <FormattedMessage id="auth-footer.menu3" />,
      type: 'item',
      url: '/about'
    }
  ]
};

export const purchasesHorizontal = {
  items: [
    {
      id: 'home-sales',
      title: <FormattedMessage id="home" />,
      url: '/',
      type: 'item'
    },
    {
      id: 'list',
      title: <FormattedMessage id="auth-footer.menu1" />,
      url: '/list',
      type: 'item'
    },
    {
      id: 'services',
      title: <FormattedMessage id="auth-footer.menu2" />,
      type: 'item',
      url: '/services'
    },
    {
      id: 'about',
      title: <FormattedMessage id="auth-footer.menu3" />,
      type: 'item',
      url: '/about'
    }
  ]
};
