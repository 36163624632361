import PropTypes from 'prop-types';

// material-ui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import { Formik, Form } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

// project import
import useUtils from 'hooks/useUtils';

// ==============================|| DIALOG - USER CARD ||============================== //

export default function ContactDialog({ onClose, onSend, open, children }) {
  const intl = useIntl();
  const theme = useTheme();
  const { displaySnackbar } = useUtils();

  const validationSchema = yup.object({
    nom: yup.string().required(),
    prenom: yup.string().required(),
    company: yup.string().required(),
    phone: yup
      .number()
      .test(
        'len',
        <FormattedMessage
          id="address-dialog.phone-number-should-be-at-least-{number}-digits"
          values={{
            number: 9
          }}
        />,
        (val) => val?.toString().length >= 9
      )
      .required(<FormattedMessage id="address-dialog.phone-number-is-required" />),
    mail: yup
      .string()
      .email(<FormattedMessage id="address-dialog.invalid-email-address" />)
      .max(255)
      .required(<FormattedMessage id="address-dialog.email-is-required" />),
    rgpd: yup.bool().isTrue()
  });

  const initialValues = { nom: '', prenom: '', company: '', siret: '', phone: '', mail: '', rgpd: false };

  const onSubmitHandler = async (values) => {
    console.log('🚀 ~ onSubmitHandler ~ onSubmitHandler:??');
    const body = {
      nom: values.nom,
      prenom: values.prenom,
      company: values.company,
      siret: values.siret,
      phone: values.phone,
      mail: values.mail,
      rgpd: values.rgpd
    };

    await onSend(body);
    onClose();

    displaySnackbar(intl.formatMessage({ id: 'message-offer-sent-to-client' }));
  };

  return (
    <>
      <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
        <Box>
          <DialogTitle
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 10,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '0 0 40px 40px',
              color: 'white'
            }}
            align="center"
          >
            <Typography style={{ fontFamily: 'promoove', fontWeight: 'bold', fontSize: '2rem' }}>
              <FormattedMessage id="Nous contacter" />
            </Typography>
          </DialogTitle>
          <Formik validateOnMount={true} validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitHandler}>
            {({ handleChange, setFieldValue, handleBlur, values, isValid, errors, touched }) => (
              <Form>
                <DialogContent>
                  <Grid container justifyContent="center">
                    <Grid item xs={10} md={8}>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <DialogContentText sx={{ mt: 2 }}>
                            <Typography variant="p">
                              <FormattedMessage
                                id="Pour toute question, vous pouvez nous contacter via le formulaire suivant.
Vous pouvez aussi consulter les coordonnées de nos agences pour nous téléphoner ou nous rendre visite."
                              />
                            </Typography>
                          </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="nom"
                            type="string"
                            fullWidth
                            variant="standard"
                            label={
                              <Typography variant="p" sx={{ fontFamily: 'Promoove', fontSize: '1.2rem' }}>
                                <FormattedMessage id="Nom" />
                              </Typography>
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nom}
                          />
                          {touched.nom && errors.nom && (
                            <FormHelperText error id="nom-error">
                              {errors.nom}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="prenom"
                            type="string"
                            fullWidth
                            variant="standard"
                            label={
                              <Typography variant="p" sx={{ fontFamily: 'Promoove', fontSize: '1.2rem' }}>
                                <FormattedMessage id="Prénom" />
                              </Typography>
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.prenom}
                          />
                          {touched.prenom && errors.company && (
                            <FormHelperText error id="company-error">
                              {errors.company}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="company"
                            type="string"
                            fullWidth
                            variant="standard"
                            label={
                              <Typography variant="p" sx={{ fontFamily: 'Promoove', fontSize: '1.2rem' }}>
                                <FormattedMessage id="Société" />
                              </Typography>
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.company}
                          />
                          {touched.company && errors.company && (
                            <FormHelperText error id="company-error">
                              {errors.company}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="siret"
                            type="string"
                            fullWidth
                            variant="standard"
                            label={
                              <Typography variant="p" sx={{ fontFamily: 'Promoove', fontSize: '1.2rem' }}>
                                <FormattedMessage id="SIRET" />
                              </Typography>
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.siret}
                          />
                          {touched.siret && errors.siret && (
                            <FormHelperText error id="siret-error">
                              {errors.siret}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="phone"
                            type="number"
                            fullWidth
                            variant="standard"
                            label={
                              <Typography variant="p" sx={{ fontFamily: 'Promoove', fontSize: '1.2rem' }}>
                                <FormattedMessage id="Téléphone" />
                              </Typography>
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.phone}
                          />
                          {touched.phone && errors.phone && (
                            <FormHelperText error id="phone-error">
                              {errors.phone}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="mail"
                            type="mail"
                            fullWidth
                            variant="standard"
                            label={
                              <Typography variant="p" sx={{ fontFamily: 'Promoove', fontSize: '1.2rem' }}>
                                <FormattedMessage id="E-mail" />
                              </Typography>
                            }
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                          />
                          {touched.email && errors.email && (
                            <FormHelperText error id="email-error">
                              {errors.email}
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          {children}
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={<Checkbox id="rgpd" name="rgpd" />}
                            onChange={() => setFieldValue('rgpd', !values.rgpd)}
                            checked={values.rgpd}
                            value="start"
                            label="Consentement RGPD"
                            labelPlacement="start"
                          />
                          <Grid container sx={{ backgroundColor: theme.palette.primary.lighter, p: 2 }}>
                            <Grid item xs={12}>
                              <Typography variant="p" sx={{ fontSize: '0.8rem' }}>
                                <FormattedMessage id="Je certifie que je suis majeur et donne mon consentement quant au traitement et à l'utilisation des informations communiquées via ce formulaire, dont j’ai pris connaissance sur cette même page. Je suis également informé(e) de la possibilité et des moyens qui me sont donnés pour retirer ce consentement à tout moment, ou exercer mes droits sur mes données personnelles." />
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ position: 'sticky', bottom: 10, right: 10 }}>
                  <Button variant="contained" color="blue" onClick={onClose}>
                    {<FormattedMessage id="offer-dialog.cancel" />}
                  </Button>
                  <Button variant="contained" type="submit" disabled={!isValid}>
                    {<FormattedMessage id="send" />}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    </>
  );
}
ContactDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
  children: PropTypes.node
};
