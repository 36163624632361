import PropTypes from 'prop-types';
// ==============================|| AUTH BLUR BACK SVG ||============================== //

const PromooveLogoO = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 207.000000 163.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,163.000000) scale(0.100000,-0.100000)" fill={props.fill} className={props.class} stroke="none">
        <path
          d="M1240 1604 c-335 -61 -729 -275 -961 -521 -307 -325 -355 -679 -125
-909 65 -65 149 -111 252 -140 83 -22 277 -30 383 -14 326 48 691 230 941 467
126 119 188 201 250 328 123 252 102 460 -62 631 -65 68 -137 111 -243 144
-93 30 -310 37 -435 14z m139 -265 c130 -21 230 -90 281 -192 57 -116 50 -231
-22 -370 -129 -249 -496 -474 -803 -494 -116 -7 -192 7 -285 54 -122 62 -180
158 -180 297 1 229 199 462 522 616 177 84 339 114 487 89z"
        />
      </g>
    </svg>
  );
};

PromooveLogoO.propTypes = {
  fill: PropTypes.string,
  class: PropTypes.string
};

export default PromooveLogoO;
