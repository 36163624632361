// ==============================|| OVERRIDES - CONTAINER ||============================== //

export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100% !important'
        }
      }
    }
  };
}
