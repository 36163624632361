import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
import LogoMain from './LogoMain';
import LogoIcon from './LogoIcon';
import config from 'config';
import { useDispatch } from 'react-redux';
import { activeItem } from 'store/reducers/menu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx, to }) => {
  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state.menu);

  const handleClick = () => {
    dispatch(activeItem({ userRole, openItem: [''] }));
  };
  return (
    <ButtonBase disableRipple onClick={handleClick} component={Link} to={!to ? config.defaultPath : to} sx={sx}>
      {isIcon ? <LogoIcon /> : <LogoMain reverse={reverse} />}
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
