// third-party
import { useIntl } from 'react-intl';

function useCountryConfig() {
  const intl = useIntl();

  const languageConfig = {
    fr: { value: 'fr_FR' },
    en: { value: 'en_US' },
    es: { value: 'es_ES' }
  };
  const countriesConfig = {
    AD: { value: 'AD', label: intl.formatMessage({ id: 'countries.AD' }) },
    AT: { value: 'AT', label: intl.formatMessage({ id: 'countries.AT' }) },
    BE: { value: 'BE', label: intl.formatMessage({ id: 'countries.BE' }) },
    BG: { value: 'BG', label: intl.formatMessage({ id: 'countries.BG' }) },
    BY: { value: 'BY', label: intl.formatMessage({ id: 'countries.BY' }) },
    CH: { value: 'CH', label: intl.formatMessage({ id: 'countries.CH' }) },
    CZ: { value: 'CZ', label: intl.formatMessage({ id: 'countries.CZ' }) },
    DE: { value: 'DE', label: intl.formatMessage({ id: 'countries.DE' }) },
    DK: { value: 'DK', label: intl.formatMessage({ id: 'countries.DK' }) },
    DZ: { value: 'DZ', label: intl.formatMessage({ id: 'countries.DZ' }) },
    EE: { value: 'EE', label: intl.formatMessage({ id: 'countries.EE' }) },
    ES: { value: 'ES', label: intl.formatMessage({ id: 'countries.ES' }) },
    FI: { value: 'FI', label: intl.formatMessage({ id: 'countries.FI' }) },
    FR: { value: 'FR', label: intl.formatMessage({ id: 'countries.FR' }) },
    GR: { value: 'GR', label: intl.formatMessage({ id: 'countries.GR' }) },
    HR: { value: 'HR', label: intl.formatMessage({ id: 'countries.HR' }) },
    HU: { value: 'HU', label: intl.formatMessage({ id: 'countries.HU' }) },
    IT: { value: 'IT', label: intl.formatMessage({ id: 'countries.IT' }) },
    LT: { value: 'LT', label: intl.formatMessage({ id: 'countries.LT' }) },
    LU: { value: 'LU', label: intl.formatMessage({ id: 'countries.LU' }) },
    LV: { value: 'LV', label: intl.formatMessage({ id: 'countries.LV' }) },
    MA: { value: 'MA', label: intl.formatMessage({ id: 'countries.MA' }) },
    MC: { value: 'MC', label: intl.formatMessage({ id: 'countries.MC' }) },
    MD: { value: 'MD', label: intl.formatMessage({ id: 'countries.MD' }) },
    ME: { value: 'ME', label: intl.formatMessage({ id: 'countries.ME' }) },
    MF: { value: 'MF', label: intl.formatMessage({ id: 'countries.MF' }) },
    NL: { value: 'NL', label: intl.formatMessage({ id: 'countries.NL' }) },
    NO: { value: 'NO', label: intl.formatMessage({ id: 'countries.NO' }) },
    PL: { value: 'PL', label: intl.formatMessage({ id: 'countries.PL' }) },
    PT: { value: 'PT', label: intl.formatMessage({ id: 'countries.PT' }) },
    RO: { value: 'RO', label: intl.formatMessage({ id: 'countries.RO' }) },
    RS: { value: 'RS', label: intl.formatMessage({ id: 'countries.RS' }) },
    RU: { value: 'RU', label: intl.formatMessage({ id: 'countries.RU' }) },
    SE: { value: 'SE', label: intl.formatMessage({ id: 'countries.SE' }) },
    SI: { value: 'SI', label: intl.formatMessage({ id: 'countries.SI' }) },
    SK: { value: 'SK', label: intl.formatMessage({ id: 'countries.SK' }) },
    SM: { value: 'SM', label: intl.formatMessage({ id: 'countries.SM' }) },
    SZ: { value: 'SZ', label: intl.formatMessage({ id: 'countries.SZ' }) },
    TR: { value: 'TR', label: intl.formatMessage({ id: 'countries.TR' }) },
    UA: { value: 'UA', label: intl.formatMessage({ id: 'countries.UA' }) }
  };

  return {
    countriesConfig,
    languageConfig
  };
}

export { useCountryConfig };
