// material-ui
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { Grid, Link, Stack, Typography, useMediaQuery } from '@mui/material';

import useConfig from 'hooks/useConfig';

const Footer = () => {
  const theme = useTheme();
  const { i18n } = useConfig();
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Grid
      id="footer"
      container
      justifyContent={'center'}
      sx={{ backgroundColor: theme.palette.blue.main, borderRadius: '100px 100px 0 0', p: 2, pt: 0, mt: 10 }}
    >
      <Grid item xs={12} sm={11} md={10} xl={9} p={5}>
        <Grid container>
          <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
            <Typography variant="body2" sx={{ fontSize: matchDownSM ? '0.9rem' : '1.2rem' }} color={'white'}>
              <FormattedMessage id="auth-footer.title1" />
            </Typography>
          </Grid>
          <Grid item xs={12} justifyContent={'center'} textAlign={'center'}>
            <Typography variant="body2" sx={{ fontSize: matchDownSM ? '0.9rem' : '1.2rem' }} color={'white'}>
              <FormattedMessage id="auth-footer.title2" />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} justifyContent={'center'} textAlign={matchDownSM ? 'center' : 'left'}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: 'Promoove Medium', fontWeight: 'bold', fontSize: '1.2rem' }}
                  color={theme.palette.primary.main}
                >
                  <FormattedMessage id="auth-footer.direction" />
                </Typography>
                <Typography variant="body2" sx={{ fontSize: matchDownSM ? '0.9rem' : '1.2rem' }} color={'white'}>
                  31 Av. de Paris, 91670 Angerville, France
                </Typography>
              </Grid>
              <Grid item xs={12} justifyContent={'center'} textAlign={matchDownSM ? 'center' : 'left'}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: 'Promoove Medium', fontWeight: 'bold', fontSize: '1.2rem' }}
                  color={theme.palette.primary.main}
                >
                  <FormattedMessage id="auth-footer.schedule" />
                </Typography>
                <Typography variant="body2" sx={{ fontSize: matchDownSM ? '0.9rem' : '1.2rem' }} color={'white'}>
                  <FormattedMessage id="auth-footer.schedule_hours" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} justifyContent={'center'} mt={2} textAlign={matchDownSM ? 'center' : 'right'} alignContent={'center'}>
            <Stack sx={{ float: 'center' }}>
              <Typography
                component={Link}
                variant="body2"
                href={`/list`}
                underline="hover"
                color={theme.palette.primary.main}
                sx={{ fontFamily: 'Promoove Medium', letterSpacing: 3, fontWeight: 'bold', fontSize: '1.2rem' }}
              >
                <FormattedMessage id="auth-footer.menu1" />
              </Typography>
              <Typography
                component={Link}
                variant="body2"
                href={`/services`}
                underline="hover"
                color={theme.palette.primary.main}
                sx={{ fontFamily: 'Promoove Medium', letterSpacing: 3, fontWeight: 'bold', fontSize: '1.2rem' }}
              >
                <FormattedMessage id="auth-footer.menu2" />
              </Typography>
              <Typography
                component={Link}
                variant="body2"
                href={`/services`}
                underline="hover"
                color={theme.palette.primary.main}
                sx={{ fontFamily: 'Promoove Medium', letterSpacing: 3, fontWeight: 'bold', fontSize: '1.2rem' }}
              >
                <FormattedMessage id="auth-footer.menu3" />
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          justifyContent="space-between"
          spacing={1}
          textAlign={matchDownSM ? 'center' : 'inherit'}
        >
          <Typography variant="subtitle2" color="white" component="span">
            <FormattedMessage id="auth-footer.this-site-is-protected-by" />{' '}
            <Typography
              hrefLang={i18n}
              component={Link}
              variant="subtitle2"
              href={`/privacy?lang=${i18n}`}
              target="_blank"
              underline="hover"
            >
              <FormattedMessage id="auth-footer.privacy-policy" />
            </Typography>
          </Typography>

          <Stack direction={'row'} spacing={0.5} alignSelf={matchDownSM ? 'center' : 'inherit'}>
            <Typography variant="subtitle2" color="white">
              <FormattedMessage id="auth-footer.terms-and-conditions" />
            </Typography>
            <Typography
              variant="subtitle2"
              color="info"
              hrefLang={i18n}
              component={Link}
              href={`/terms?lang=${i18n}`}
              target="_blank"
              underline="hover"
            >
              <FormattedMessage id="auth-footer.terms-and-conditions-generic" />
            </Typography>
            <Typography variant="subtitle2" color="secondary">
              <FormattedMessage id="auth-register.end" />
            </Typography>
            <Typography
              variant="subtitle2"
              color="info"
              hrefLang={i18n}
              component={Link}
              href={`/terms-vente?lang=${i18n}`}
              target="_blank"
              underline="hover"
            >
              <FormattedMessage id="auth-footer.terms-and-conditions-vente" />
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Footer;
