import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// third-party
import { Provider as ReduxProvider } from 'react-redux';

// scroll bar
import 'simplebar/dist/simplebar.css';

// apex-chart
import 'assets/third-party/apex-chart.css';
import 'assets/third-party/react-table.css';
import 'assets/fonts';

// load mock apis
import 'api';

// project import
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from 'store';
import { ConfigProvider } from 'contexts/ConfigContext';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';

import ReactGA from 'react-ga4';
if (process.env.NODE_ENV !== 'development') ReactGA.initialize('G-JECNMWRJ6D');

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //
ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
