import PropTypes from 'prop-types';

// project import
import ThemeClient from './themeClient';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors, userRole, mode) => {
  return ThemeClient(colors, mode);
};

Theme.propTypes = {
  colors: PropTypes.object,
  presetColor: PropTypes.any
};

export default Theme;
