import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';

// third-party
import axios from 'axios';
import { auth } from 'auth';
import { useIntl } from 'react-intl';
import { onAuthStateChanged, applyActionCode, signInWithEmailAndPassword, signOut, getIdTokenResult } from 'firebase/auth';
import {
  getUserLanguage,
  register as registerApi,
  getPublicUserCompanyByFirebaseId,
  getPublicUserContactByFirebaseId
} from 'api/UserService';

// action - state management
import { LOGIN, LOGOUT, REGISTER, CLEAN, UPDATE } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import useUtils from 'hooks/useUtils';
import Loader from 'components/Loader';
import useConfig from 'hooks/useConfig';

// const
const REFRESH_MINUTES_MIN = 5;
const BASE_URL = process.env.REACT_APP_API_HOST;
const AxiosInstance = axios.create({ baseURL: BASE_URL });

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  company: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const intl = useIntl();
  const { onChangeLocalization } = useConfig();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [isAxiosSet, setIsAxiosSet] = useState(false);
  const [token, setToken] = useState('');
  const { displaySnackbar } = useUtils();

  const getLoginPayload = (contact, user) => ({
    user: {
      id: contact.id,
      email: contact.email,
      name: contact.name,
      surname: contact.surname,
      displayName: contact.displayName,
      function: contact.function,
      phone: contact.phone,
      mobile: contact.phone,
      comment: contact.comment,
      newsletter_notification: contact.newsletter_notification,
      order_notification: contact.order_notification,
      system_notification: contact.system_notification,
      uid: user.uid,
      emailVerified: user.emailVerified
    }
  });

  const getRegisterPayload = (data) => ({
    contact: {
      id: data.id,
      email: data.email
    }
  });

  const loadLang = async () => {
    const result = await getUserLanguage();
    if (result) {
      onChangeLocalization(result);
    }
  };

  const refreshToken = useCallback(async () => {
    const currentUser = auth.currentUser;
    const expirationDateMs = currentUser.stsTokenManager.expirationTime;
    const dateNowMs = Date.now();
    const leftTime = expirationDateMs - dateNowMs;
    const minutes = Math.floor(leftTime / (1000 * 60)) % 60;

    if (minutes < REFRESH_MINUTES_MIN) {
      console.info("Token has expired, so let's refresh it", minutes < REFRESH_MINUTES_MIN);
      const tokenResult = await getIdTokenResult(currentUser, true);
      setToken(tokenResult.token);
      return tokenResult.token;
    }

    return token;
  }, [token]);

  const refreshContext = useCallback(async () => {
    if (state?.user?.uid) {
      const userCompany = (await getPublicUserCompanyByFirebaseId(state.user.uid))?.data?.data;
      const userContact = (await getPublicUserContactByFirebaseId(state.user.uid))?.data?.data;
      const payload = {
        user: {
          ...getLoginPayload(userContact, state?.user).user
        },
        company: {
          id: userCompany.id,
          name: userCompany.name,
          status: userCompany.status,
          address_fiscale: userCompany.address_fiscale,
          id_fiscale: userCompany.id_fiscale,
          country: userCompany.country
        }
      };
      dispatch({ type: UPDATE, payload });
    }
  }, [state?.user]);

  // INTERCEPTOR
  useEffect(() => {
    if (!state.isInitialized) return;
    if (state.user && !state.user.emailVerified) {
      console.log('🚀 ~ useEffect ~ ate.user:', state.user);
      const message = intl.formatMessage({
        id: 'firebase-context.message-oups-something-went-wrong-email-not-verified'
      });
      displaySnackbar(message, 'error');
      logout();
    }

    const onResponse = (response) => {
      if (
        response?.config?.method === 'put' &&
        response.status === 200 &&
        (response.config.url.includes('/api/v1/public-users') || response.config.url.includes('/api/v1/public-user/company'))
      ) {
        console.log('🚀 ~ onResponse ~ response:', response);
        refreshContext();
      }
      return response;
    };

    const onError = async (error) => {
      console.error('Error caught in AuthInterceptor', error);
      switch (error.response?.status) {
        case 400:
          {
            const message = intl.formatMessage({
              id: 'firebase-context.message-oups-something-went-wrong-request-may-be-invalid'
            });
            displaySnackbar(message, 'error');
          }
          break;
        case 401:
          logout();
          break;
        // 403 ??
        case 500:
          {
            const message = intl.formatMessage({
              id: 'firebase-context.message-oups-something-went-wrong-unexpected-internal-error-occurred'
            });
            displaySnackbar(message, 'error');
          }
          break;
      }
      return Promise.reject(error);
    };

    const onRequest = async (request) => {
      if (state.user) {
        const token = await refreshToken();
        request.headers['auth-token'] = token;
      }
      return request;
    };

    const responseInterceptor = AxiosInstance.interceptors.response.use(onResponse, onError);
    const requestInterceptor = AxiosInstance.interceptors.request.use(onRequest);
    setIsAxiosSet(true);

    return () => {
      AxiosInstance.interceptors.response.eject(responseInterceptor);
      AxiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [state, displaySnackbar, intl, refreshToken, refreshContext]);

  // LOGIN
  useEffect(
    () =>
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdTokenResult(true).then(async function (idTokenResult) {
            const userCompany = (await getPublicUserCompanyByFirebaseId(user.uid))?.data?.data;
            const userContact = (await getPublicUserContactByFirebaseId(user.uid))?.data?.data;
            if (!userCompany || ['blacklisted', 'demand', 'target', 'referencement'].includes(userCompany.status)) {
              dispatch({ type: LOGOUT });
              dispatch({ type: CLEAN });

              const message = intl.formatMessage({
                id: 'firebase-context.message-oups-something-went-wrong-pending-validate'
              });
              displaySnackbar(message, 'error');
            } else {
              setToken(idTokenResult.token);
              loadLang();
              const payload = {
                user: {
                  ...getLoginPayload(userContact, user).user
                },
                company: {
                  id: userCompany.id,
                  name: userCompany.name,
                  status: userCompany.status,
                  address: userCompany.address_fiscale,
                  id_fiscale: userCompany.id_fiscale,
                  id_file: userCompany.id_file,
                  kbis_file: userCompany.kbis_file,
                  country: userCompany.country
                }
              };
              dispatch({ type: LOGIN, payload });
            }
          });
        } else {
          dispatch({ type: LOGOUT });
          dispatch({ type: CLEAN });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const login = (email, password) => signInWithEmailAndPassword(auth, email, password);

  const logout = () => {
    signOut(auth);
    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAN });
  };

  const register = async (values) => {
    const registerResponse = await registerApi(values);
    if (registerResponse) {
      if (registerResponse.data?.displayName) {
        dispatch({ type: REGISTER, payload: getRegisterPayload(registerResponse.data) });
      }
    }
    return registerResponse;
  };

  const verifyMail = async (actionCode) => {
    try {
      return await applyActionCode(auth, actionCode);
    } catch (error) {
      console.log(error);
    }
  };

  if (!isAxiosSet || !state.isInitialized) {
    return <Loader />;
  }

  return <FirebaseContext.Provider value={{ ...state, login, logout, register, verifyMail, token }}>{children}</FirebaseContext.Provider>;
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;
export { AxiosInstance };
