// material-ui
import { Box, Grid } from '@mui/material';

import logo from './promooveLogo.webp';

// ==============================|| AUTH BLUR BACK SVG ||============================== //

const PromooveLogo = () => {
  return (
    <Box>
      <Grid container>
        <Grid>
          <img
            alt="Promoove Main Logo"
            style={{
              width: '100%'
            }}
            src={logo}
          ></img>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromooveLogo;
