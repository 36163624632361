// material-ui
import { Box, useMediaQuery } from '@mui/material';
import PromooveLogo from 'assets/images/logos/PromooveLogo';

// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Box width={matchDownSM ? '200px' : '250px'} fill="none" alignItems={'center'}>
      <PromooveLogo />
    </Box>
  );
};

export default LogoMain;
